import LoginForm from '../components/Login/LoginForm';

const Login = (props) => {
    return (
      <div>
        <LoginForm/>
      </div>
    );
  };
  
export default Login;
