import React, { useState } from "react";
import "./LoginForm.css";
import { useSignIn } from "react-auth-kit";

const LoginForm = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const signIn = useSignIn();

  const UsernameChangeHandler = (event) => {
    setUsername(event.target.value);
  };

  const PasswordChangeHandler = (event) => {
    setPassword(event.target.value);
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const loginData = {
      username: username,
      password: password,
    };

    console.log(loginData);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(loginData),
      mode: "cors",
    };
    fetch("https://cellar-385420.ue.r.appspot.com/token", requestOptions)
    // fetch("http://192.168.86.71:8080/token", requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response.token) {
          signIn({
            token: response.token,
            expiresIn: 3600,
            tokenType: "Bearer",
            authState: { username: loginData.username },
          });
          // Redirect to "/wine" upon successful login
          window.location.href = "/wine";
        } else {
          // Handle login error
          console.error("Login failed");
        }
      })
      .catch((error) => {
        // Handle network or API call error
        console.error("Error occurred during login:", error);
      });
  };

  return (
    <form onSubmit={submitHandler}>
      <div className="login__control">
        <div className="login__control">
          <label>username</label>
          <input
            type="text"
            value={username}
            onChange={UsernameChangeHandler}
          />
        </div>
        <div className="login__control">
          <label>password</label>
          <input
            type="text"
            value={password}
            onChange={PasswordChangeHandler}
          />
        </div>
      </div>
      <div className="login__actions">
        <button type="submit">Login {props.bevType}</button>
      </div>
    </form>
  );
};

export default LoginForm;
