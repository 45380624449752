import React, { useState, useEffect } from "react";
import "./BeverageForm.css";
import { useAuthUser } from "react-auth-kit";
import { useAuthHeader } from "react-auth-kit";

const BeverageForm = (props) => {
    const authUser = useAuthUser();
    const TOTAL_STEPS = (props.bevType === "Beer") ? 9 : 10;
    
    const authHeader = useAuthHeader();

    const [currentStep, setCurrentStep] = useState(1);
    const [region, setRegion] = useState("");
    const [style, setStyle] = useState("");
    const [size, setSize] = useState("");
    const [subRegion, setSubRegion] = useState("");
    const [subStyle, setSubStyle] = useState("");
    const [maker, setMaker] = useState("");
    const [enteredName, setEnteredName] = useState("");
    const [abv, setAbv] = useState("");
    const [area, setArea] = useState("");
    const [color, setColor] = useState("");
    const [sparkling, setSparkling] = useState("");
    const makerDisplayValue = (props.bevType === "Beer") ? "Brewery" : "Wine Maker";
  

    const [regions, setRegions] = useState([]);
    const [styles, setStyles] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [subRegions, setSubRegions] = useState([]);
    const [subStyles, setSubStyles] = useState([]);
    const [makers, setMakers] = useState([]);
    const [names, setNames] = useState([]);
    const [colors, setColors] = useState([]);
    const [quantity, setQuantity] = useState("");
    const [selectedRegionId, setSelectedRegionId] = useState(null);
    const [selectedMakerId, setSelectedMakerId] = useState(null);

    //custom user values
    const [customSubRegionId, setCustomSubRegionId] = useState([]);

    const [newCountry, setNewCountry] = useState("");
    const [newSubRegion, setNewSubRegion] = useState(""); // New sub-region input
    const [newArea, setNewArea] = useState(""); // New area input
    const [newSizeAmount, setNewSizeAmount] = useState("");
    const [newSizeUnits, setNewSizeUnits] = useState("");
    const [newMaker, setNewMaker] = useState("");
    const [newName, setNewName] = useState("");
    const [newStyle, setNewStyle] = useState("");
    const [newSubStyle, setNewSubStyle] = useState("");



    useEffect(() => {
        const requestOptions = {
            method: "GET",
            headers: {
              Authorization: authHeader(),
              "Content-Type": "application/json",
            },
        };
        if (currentStep === 1) {
        fetch(`https://cellar-385420.ue.r.appspot.com/cellar/new-beverage-0?type=${props.bevType}`, requestOptions)
        // fetch(`1`https://cellar-385420.ue.r.appspot.com/cellar/new-beverage-0?type=${props.bevType}`, requestOptions)
          .then(response => response.json())
          .then(data => {
            // Sort the regions array based on your desired sorting criteria
            const sortedRegions = data.regions.sort((a, b) => a.localeCompare(b));
          
            // Sort the styles array based on your desired sorting criteria
            const sortedStyles = data.styles.sort((a, b) => a.localeCompare(b));
          
            // Set the sorted regions and styles arrays using setRegions and setStyles
            setRegions(sortedRegions);
            setStyles(sortedStyles);
          
            // Set sizes and colors without sorting
            setSizes(data.sizes);
            setColors(data.colors);
          })
          
          .catch(error => {
            console.error("Error fetching data:", error);
          });
        }      
        if (currentStep === 3) {
          fetch(`https://cellar-385420.ue.r.appspot.com/cellar/sub-regions?country=${region}`, requestOptions)
            .then(response => response.json())
            .then(data => {
              setSubRegions(data);
            })
            .catch(error => {
              console.error("Error fetching sub-regions:", error);
            });
        }
        if (currentStep === 5) {
          fetch(`https://cellar-385420.ue.r.appspot.com/cellar/sub-styles?style=${style}`, requestOptions)
            .then(response => response.json())
            .then(data => {
              const sortedSubStyles = data.sort((a, b) => a.name.localeCompare(b.name));

              // Set the sorted subStyles array using setSubStyles
              setSubStyles(sortedSubStyles);
            })
            .catch(error => {
              console.error("Error fetching sub-styles:", error);
            });
        }
        if (currentStep === 7) {
          fetch(`https://cellar-385420.ue.r.appspot.com/cellar/makers-by-region?regionId=${subRegion}`, requestOptions)
            .then(response => response.json())
            .then(data => {
              setMakers(data); // Populate makers state with fetched data
            })
            .catch(error => {
              console.error("Error fetching makers:", error);
            });
            if (makers.length > 0) {
              setSelectedMakerId(makers[0].id);
            }
          
        }
        if (currentStep === 8) {
          fetch(`https://cellar-385420.ue.r.appspot.com/cellar/names-by-maker?makerId=${selectedMakerId}`, requestOptions)
            .then(response => response.json())
            .then(data => {
              setNames(data);
            })
            .catch(error => {
              console.error("Error fetching names by maker:", error);
            });
        }
      }, [currentStep, region, style, maker, enteredName, subRegion]);

  const QuantityHandler = (event) => {
    setQuantity(event.target.value);
  };

  const SizeHandler = (event) => {
    setSize(event.target.value);
  };

  const RegionHandler = (event) => {
    setRegion(event.target.value);
  };

  const SubRegionHandler = (event) => {
    const selectedSubRegion = event.target.value;
    
    if (selectedSubRegion === "new") {
      setSubRegion("new");
    } else {
      setSubRegion(selectedSubRegion);
    }
    
  };

  const selectedRegionIdHandler = (event) => {
    setSelectedRegionId(event.target.value);
  }

  const addNewSize = () => {
    const tempSize = {
      amount: newSizeAmount,
      unit: newSizeUnits,
      id: -sizes.length - 1
    };
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader()
      },
      body: JSON.stringify(tempSize)
    };
  
    fetch("https://cellar-385420.ue.r.appspot.com/cellar/add-size", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        tempSize.id = data;
        setSizes([tempSize, ...sizes]);
        setSize(data);
        setCurrentStep(currentStep + 1);
      })
      .catch(error => {
        console.error("Error adding new size:", error);
      });
  };

  const addNewRegion = () => {
    const newRegion = {
      country: newCountry,
      subRegion: newSubRegion,
      area: newArea,
      id: -regions.length - 1
    };
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader()
      },
      body: JSON.stringify(newRegion)
    };
  
    fetch("https://cellar-385420.ue.r.appspot.com/cellar/add-region", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        newRegion.id = data;
        setRegions([newRegion.country, ...regions])
        setRegion(newRegion.country);
        setSubRegions([...subRegions, newRegion]);
        setSelectedRegionId(data)
        setCurrentStep(currentStep + 1);
      })
      .catch(error => {
        console.error("Error adding new sub-region:", error);
      });
  };

  const addNewSubRegion = () => {
    const newRegion = {
      country: region,
      subRegion: newSubRegion,
      area: newArea,
      id: -subRegions.length - 1
    };
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader()
      },
      body: JSON.stringify(newRegion)
    };
  
    fetch("https://cellar-385420.ue.r.appspot.com/cellar/add-region", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        newRegion.id = data;
        setSubRegions([...subRegions, newRegion]);
        setSubRegion(data);
        setSelectedRegionId(data)
        setCurrentStep(currentStep + 1);
      })
      .catch(error => {
        console.error("Error adding new sub-region:", error);
      });
  };

  const addNewMaker = () => {
    const tempMaker = {
      maker: newMaker,
      region_id: parseInt(subRegion),
      id: -makers.length - 1
    };
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader()
      },
      body: JSON.stringify(tempMaker)
    };
  
    fetch("https://cellar-385420.ue.r.appspot.com/cellar/add-maker", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        tempMaker.id = data;
        setMakers([tempMaker, ...makers]);
        setMaker(tempMaker.id);
        setNewMaker('');
        setCurrentStep(currentStep + 1);
      })
      .catch(error => {
        console.error("Error adding new maker:", error);
      });
  };
  

  const addNewName = () => {
    const tempName = {
      name: newName,
      maker_id: maker,
      id: -names.length - 1
    };
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader()
      },
      body: JSON.stringify(tempName)
    };
  
    fetch("https://cellar-385420.ue.r.appspot.com/cellar/add-name", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        tempName.id = data;
        setNames([tempName, ...names]);
        setEnteredName(tempName.id);
        setCurrentStep(currentStep + 1);
        
      })
      .catch(error => {
        console.error("Error adding new name:", error);
      });
  };

  const addNewStyle = () => {
    const tempStyle = {
      type: props.bevType,
      name: newStyle,
      id: -styles.length - 1
    };
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader()
      },
      body: JSON.stringify(tempStyle)
    };
  
    fetch("https://cellar-385420.ue.r.appspot.com/cellar/add-style", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        tempStyle.id = data;
        setStyles([tempStyle.name, ...styles]);
        setStyle(tempStyle.name); // Automatically select the newly added style
        setCurrentStep(currentStep + 1);
      })
      .catch(error => {
        console.error("Error adding new style:", error);
      });
  };
    
  const addNewSubStyle = () => {
    const tempStyle = {
      type: props.bevType,
      name: style,
      subStyle: newSubStyle,
      id: -subStyles.length - 1
    };
  
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader()
      },
      body: JSON.stringify(tempStyle)
    };
  
    fetch("https://cellar-385420.ue.r.appspot.com/cellar/add-style", requestOptions)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        tempStyle.id = data;
        setSubStyles([tempStyle, ...subStyles]);
        setSubStyle(tempStyle.id); // Automatically select the newly added style
        setCurrentStep(currentStep + 1);
      })
      .catch(error => {
        console.error("Error adding new sub-style:", error);
      });
  };


  const StyleHandler = (event) => {
    setStyle(event.target.value);
  };

  const SubStyleHandler = (event) => {
    console.log(event.target.value)
    setSubStyle(event.target.value);
  };

  const MakerHandler = (event) => {
    const selectedMakerId = event.target.value;
  
    if (selectedMakerId === "new") {
      setMaker("new"); // Set the maker state to indicate adding a new maker
    } else {
      setMaker(selectedMakerId); // Set the selected maker's ID
    }
  };

  const ColorHandler = (event) => {
    setColor(event.target.value);
  }

  const NameChangeHandler = (event) => {
    const selectedName = event.target.value;
    if(selectedName === "new") {
      setEnteredName("new")
    } else {
      setEnteredName(event.target.value);
    }
  };

  const AbvHandler = (event) => {
    setAbv(event.target.value);
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const previousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const getStepContent = () => {
    switch (currentStep) {
      case 1:
          return (
            <div className="new-beverage__control">
              <label>Quantity</label>
              <input type="number" value={quantity} onChange={QuantityHandler} />
            </div>
          );
      case 2:
        return (
            <div className="new-beverage__control">
            <label>Size</label>
            <select value={size} onChange={SizeHandler}>
              <option value="">Select Size</option>
              {sizes.map((sizeOption, index) => (
                <option key={index} value={sizeOption.id}>
                  {sizeOption.amount} {sizeOption.unit}
                </option>
              ))}
              <option value="new">Add New Size</option>
            </select>
            {size === "new" && (
              <div>
                <input
                  type="text"
                  placeholder="Enter Size"
                  value={newSizeAmount}
                  onChange={(event) => setNewSizeAmount(event.target.value)}
                />
                <input
                  type="text"
                  placeholder="Enter Units"
                  value={newSizeUnits}
                  onChange={(event) => setNewSizeUnits(event.target.value)}
                />
                <button onClick={addNewSize}>Save Size</button>
              </div>
            )}
          </div>
        );
      case 3:
        return (
          <div className="new-beverage__control">
            <label>Region</label>
            <select value={region} onChange={RegionHandler}>
              <option value="">Select Region</option>
              {regions.map((regionOption, index) => (
                <option key={index} value={regionOption}>
                  {regionOption}
                </option>
              ))}
              <option value="new">Add New Region</option>
            </select>
            {region === "new" && (
              <div>
                <input
                  type="text"
                  placeholder="Enter Country"
                  value={newCountry}
                  onChange={(event) => setNewCountry(event.target.value)}
                />
                <input
                  type="text"
                  placeholder="Enter Sub-Region (Optional)"
                  value={newSubRegion}
                  onChange={(event) => setNewSubRegion(event.target.value)}
                />
                <input
                  type="text"
                  placeholder="Enter Area (Optional)"
                  value={newArea}
                  onChange={(event) => setNewArea(event.target.value)}
                />
                <button onClick={addNewRegion}>Save Region</button>
              </div>
            )}
          </div>
        );
      case 4:
        return (
          <div className="new-beverage__control">
            <label>Sub-Region</label>
            <select value={subRegion} onChange={SubRegionHandler}>
              <option value="">Select Sub-Region</option>
              {subRegions.map((subRegionOption) => (
                <option key={subRegionOption.id} value={subRegionOption.id}>
                  {subRegionOption.area
                    ? `${subRegionOption.subRegion} - ${subRegionOption.area}`
                    : subRegionOption.subRegion}
                </option>
              ))}
              <option value="new">Add New Sub-Region</option>
            </select>
            {subRegion === "new" && (
              <div>
                <input
                  type="text"
                  placeholder="Enter Sub-Region"
                  value={newSubRegion}
                  onChange={(event) => setNewSubRegion(event.target.value)}
                />
                <input
                  type="text"
                  placeholder="Enter Area"
                  value={newArea}
                  onChange={(event) => setNewArea(event.target.value)}
                />
                <button onClick={addNewSubRegion}>Save Sub-Region</button>
              </div>
            )}
          </div>
      );
      case 5:
        return (
          <div className="new-beverage__control">
            <label>Style</label>
            <select value={style} onChange={StyleHandler}>
              <option value="">Select Style</option>
              <option value="new">Add New Style</option>
              {styles.map((styleOption, index) => (
                <option key={index} value={styleOption}>
                  {styleOption}
                </option>
              ))}
            </select>
            {style === "new" && (
              <div>
                <input
                  type="text"
                  placeholder="Enter new style"
                  value={newStyle}
                  onChange={(event) => setNewStyle(event.target.value)}
                />
                <button onClick={addNewStyle}>Save Style</button>
              </div>
            )}
          </div>
        );
      case 6:
        return (
          <div className="new-beverage__control">
            <label>Sub-Style</label>
            <select value={subStyle} onChange={SubStyleHandler}>
              <option value="">Select Sub-Style</option>
              <option value="new">Add New Sub-Style</option>
              {subStyles.map((subStyleOption) => (
                <option key={subStyleOption.id} value={subStyleOption.id}>
                  {subStyleOption.name} - {subStyleOption.subStyle}
                </option>
              ))}
            </select>
            {subStyle === "new" && (
              <div>
                <input
                  type="text"
                  placeholder="Enter new sub-style"
                  value={newSubStyle}
                  onChange={(event) => setNewSubStyle(event.target.value)}
                />
                <button onClick={addNewSubStyle}>Save Sub-Style</button>
              </div>
            )}
          </div>
        );
      
      case 7:
        return (
          <div className="new-beverage__control">
            <label>{makerDisplayValue}</label>
            <select value={maker} onChange={MakerHandler}>
              <option value="">Select {makerDisplayValue}</option>
              <option value="new">Add New {makerDisplayValue}</option>
              {makers.map((makerOption) => (
                <option key={makerOption.id} value={makerOption.id}>
                  {makerOption.name}
                </option>
              ))}
            </select>
            {maker === "new" && (
              <div>
                <input
                  type="text"
                  placeholder={`Enter ${makerDisplayValue}`}
                  value={newMaker}
                  onChange={(event) => setNewMaker(event.target.value)}
                />
                <button onClick={addNewMaker}>Save {makerDisplayValue}</button>
              </div>
            )}
          </div>
        );
        case 8:
          return (
            <div className="new-beverage__control">
              <label>Name</label>
              <select value={enteredName} onChange={NameChangeHandler}>
                <option value="">Select Name</option>
                <option value="new">Add New Name</option>
                {names.map((nameOption) => (
                  <option key={nameOption.id} value={nameOption.id}>
                    {nameOption.name}
                  </option>
                ))}
              </select>
              {enteredName === "new" && (
                <div>
                  <input
                    type="text"
                    placeholder="Enter name"
                    value={newName}
                    onChange={(event) => setNewName(event.target.value)}
                  />
                  <button onClick={addNewName}>Save Name</button>
                </div>
              )}
            </div>
          );        
      case 9:
        return (
          <div className="new-beverage__control">
            <label>ABV</label>
            <input type="text" value={abv} onChange={AbvHandler} />
          </div>
        );
        case 10:
          if (props.bevType === "Wine") {
            return (
              <div className="new-beverage__control">
                <label>Color</label>
                <select value={color} onChange={ColorHandler}>
                  <option value="">Select Color</option>
                  {colors.map((colorOption) => (
                    <option key={colorOption} value={colorOption}>
                      {colorOption}
                    </option>
                  ))}
                </select>
                <div>
                  <label>Sparkling</label>
                  <input
                    type="checkbox"
                    checked={sparkling}
                    onChange={(event) => setSparkling(event.target.checked)}
                  />
                </div>
              </div>
            );
          } else if (props.bevType === "Beer") {
            nextStep();
            return null;
          }
          break;
    }
  };
  

  const submitHandler = (event) => {
    event.preventDefault();
    // ...
    if (currentStep < TOTAL_STEPS) {
      nextStep();
    } else {
      event.preventDefault();
      const inventoryItem = {
        beverage: {
          name: { id: parseInt(enteredName) },
          style: { id: parseInt(subStyle) },
          abv: abv,
          size: { id: parseInt(size) },
          region: { id: parseInt(subRegion) },
          color: color,
          isSparkling: { sparkling }
        },
        quantity: quantity
      };

      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authHeader() // Include your authorization header here
        },
        body: JSON.stringify(inventoryItem)
      };
      
      fetch("https://cellar-385420.ue.r.appspot.com/cellar/add-inventory-item", requestOptions)
        .then(response => {
          if (response.ok) {
            return response;//response.json(); // Return parsed JSON data in case of success
          } else if (response.status === 500) {
            throw new Error('Internal Server Error'); // Handle 500 error
          } else {
            throw new Error('Network response was not ok'); // Handle other errors
          }
        })
        .then(data => {
        })
        .catch(error => {
          console.error("Error adding inventory item:", error);
        });
    }
  };

  return (
    <form onSubmit={submitHandler}>
      {getStepContent()}
      <div className="new-beverage__actions">
        {currentStep > 1 && (
          <button type="button" onClick={previousStep}>
            Previous
          </button>
        )}
        {currentStep < TOTAL_STEPS ? (
          <button type="submit">Next</button>
        ) : (
          <button type="submit">Submit</button>
        )}
      </div>
    </form>
  );
};

export default BeverageForm;
