const Home = (props) => {
    
  
    return (
      <div>
        Home
      </div>
    );
  };

export default Home;
