import Navbar from "./Navbar"
import Wine from "./pages/Wine"
import Login from "./pages/Login"
import Beer from "./pages/Beer"
import Home from "./pages/Home"
import { Route, Routes, Navigate } from "react-router-dom"
import { RequireAuth } from "react-auth-kit"

function App() {
  return (
    <>
      <link href="https://fonts.googleapis.com/css?family=Cuprum&display=swap" rel="stylesheet"></link>
      <Navbar />
      <div className="container">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<RequireAuth loginPath="/login">
            <Home />
          </RequireAuth>} />
          <Route path="/wine" element={<RequireAuth loginPath="/login">
            <Wine />
          </RequireAuth>} />
          <Route path="/beer" element={<RequireAuth loginPath="/login">
            <Beer />
          </RequireAuth>} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      </div>
    </>
  )
}

export default App